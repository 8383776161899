// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-posts-page-jsx": () => import("./../src/templates/posts-page.jsx" /* webpackChunkName: "component---src-templates-posts-page-jsx" */),
  "component---src-templates-music-page-jsx": () => import("./../src/templates/music-page.jsx" /* webpackChunkName: "component---src-templates-music-page-jsx" */),
  "component---src-templates-album-page-jsx": () => import("./../src/templates/album-page.jsx" /* webpackChunkName: "component---src-templates-album-page-jsx" */),
  "component---src-templates-photo-page-jsx": () => import("./../src/templates/photo-page.jsx" /* webpackChunkName: "component---src-templates-photo-page-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-photos-js": () => import("./../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-shows-js": () => import("./../src/pages/shows.js" /* webpackChunkName: "component---src-pages-shows-js" */)
}

